import * as React from 'react';
import { Route, Switch } from 'wouter';
import { CmdLoader } from '@commander-services/gui-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { LanguagesContext, LanguageContextProps } from '../components/LanguagesContext';
import UserService from '../Services/UserService';
import PermissionsService, {
  PERMISSION_ALARM,
  PERMISSION_COST,
  PERMISSION_PERSON,
  PERMISSION_EXPENSE_TYPE,
  PERMISSION_IMPORTED_RECORD,
  PERMISSION_REFUELING,
  PERMISSION_RIDES,
  PERMISSION_SUPPLIER,
  PERMISSION_VEHICLE,
  PERMISSION_TACHO_CORRECTION,
  PERMISSION_PERIOD_LOCK,
  PERMISSION_GDPR_PROFILE_LIST,
  PERMISSION_GDPR_USER_LIST,
  PERMISSION_AUTO_REPORT,
  PERMISSION_VEHICLE_GROUP_CUSTOMER,
  PERMISSION_PARKING_TAG,
  PERMISSION_RIDE_TAG,
  PERMISSION_REFUELING_CARDS,
  PERMISSION_MAP_SHOW_VEHICLE,
  PERMISSION_REPORT,
  PERMISSION_ONLINE_MAP_TEMPLATES_CUSTOMER,
  PERMISSION_ONLINE_MAP_TEMPLATES_PRIVATE,
  PERMISSION_ONLINE_MAP_TEMPLATES_SYSTEM,
  PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGN,
  PERMISSION_BLUECOINS,
  PERMISSION_NEW_RIDE,
  PERMISSION_BLUECOINS_USE_CREATION_SETTINGS,
} from '../Services/PermissionsService';
import ErrorBoundary from '../components/ErrorBoundary';
import * as CustomersState from '../store/recoil/customers';
import Error404 from '../components/Error404';
import SocketConnection from '../components/SocketConnection';
import Layout from '../components/Layout';
import useMaintenance from '../components/Maintenance/hooks/useMaintenance';
import Page from '../components/Maintenance/Page';
import usePrevLocation from '../hooks/usePrevLocation';
import * as OnlineMapState from '../components/OnlineMapNew/OnlineMapState';
import * as OnlinePanelState from '../components/OnlinePanel/OnlinePanelState';
import {
  URL_ABOUT,
  URL_ACTIVATE_ACCOUNT,
  URL_ALARM,
  URL_AUTO_REPORTS,
  URL_BLUECOINS,
  URL_BLUECOINS_USAGE,
  URL_CODEBOOKS,
  URL_CORRECTIONS,
  URL_ECO_DRIVE,
  URL_EMPLOYEE,
  URL_EXPENSE_IMPORT,
  URL_EXPENSE_TYPES,
  URL_EXPIRED_PASSWORD,
  URL_FORGOT_PASSWORD,
  URL_FUEL_CARDS,
  URL_FUEL_LEVEL_PERCENTAGE,
  URL_GDPR_PROFILE,
  URL_GDPR_USER,
  URL_GRAPHS,
  URL_LOGIN,
  URL_MAPS,
  URL_ONLINE_PANEL,
  URL_REFUELING,
  URL_REPORTS,
  URL_REPORT_DOWNLOAD,
  URL_RESET_PASSWORD,
  URL_FUEL_LEVEL_LITERS,
  URL_SUPPLIERS,
  URL_THROTTLE,
  URL_TRIPS_OVERVIEW,
  URL_TRIPS_OVERVIEW_WITH_ECO_DRIVE,
  URL_VEHICLES_GROUPS,
  URL_VEHICLES_SETTINGS,
  URL_VELOCITY,
  URL_EV_BATTERY_KW,
  URL_EV_BATTERY_PERCENT,
  URL_AD_BLUE_PERCENT,
  URL_LOGOUT,
} from './constants';

const EmailDownload = React.lazy(() => import('../components/EmailDownload'));
const ForgotPassword = React.lazy(() => import('../components/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../components/ResetPassword'));
const ActivateAccount = React.lazy(() => import('../components/ActivateAccount'));
const TripMap = React.lazy(() => import('../components/TripMap'));
const EcoDriveOnline = React.lazy(() => import('../components/Graphs/EcoDrive/EcoDriveOnline'));
const FuelLevelPercOnline = React.lazy(() => import('../components/Graphs/FuelLevelPerc'));
const FuelLevelLitersOnline = React.lazy(() => import('../components/Graphs/FuelLevelLiters'));
const ThrottleOnline = React.lazy(() => import('../components/Graphs/Throttle'));
const VelocityOnline = React.lazy(() => import('../components/Graphs/Velocity'));
const OrdinaryGraphOnline = React.lazy(() => import('../components/Graphs/OrdinaryGraph'));
const Login = React.lazy(() => import('../components/Login'));
const RideMapLayout = React.lazy(() => import('../components/RideMapLayout'));
const CarsGroups = React.lazy(() => import('../components/CarsGroups'));
const Reports = React.lazy(() => import('../components/Reports'));
const AutoReports = React.lazy(() => import('../components/AutoReports'));
const FuelCards = React.lazy(() => import('../components/FuelCards'));
const Refuelings = React.lazy(() => import('../components/Refuelings'));
const Corrections = React.lazy(() => import('../components/Corrections'));
const Alarms = React.lazy(() => import('../components/Alarms'));
const Employee = React.lazy(() => import('../components/Employee'));
const Bluecoins = React.lazy(() => import('../components/Bluecoins/List'));
const BluecoinsUsage = React.lazy(() => import('../components/Bluecoins/Usage'));
const OnlinePanel = React.lazy(() => import('../components/OnlinePanel'));
const Codebooks = React.lazy(() => import('../components/Codebooks'));
const ImportRefuelings = React.lazy(() => import('../components/ImportRefuelings'));
const VehiclesSettings = React.lazy(() => import('../components/VehiclesSettings'));
const ImportRefuelingsWizard = React.lazy(
  () => import('../components/ImportRefuelingsWizard/wizard')
);
const Suppliers = React.lazy(() => import('../components/OperationCosts/Suppliers'));
const OperatingCostsOverView = React.lazy(
  () => import('../components/OperationCosts/OperatingCostsOverView')
);
const OperatingCostsTypes = React.lazy(
  () => import('../components/OperationCosts/OperatingCostsTypes')
);
const PeriodLock = React.lazy(() => import('../components/PeriodLock'));
const ExpiredPassword = React.lazy(() => import('../components/ExpiredPassword'));
const GdprProfile = React.lazy(() => import('../components/GdprProfile'));
const GdprUsersList = React.lazy(() => import('../components/GdprUsersList'));
const AboutPage = React.lazy(() => import('../components/AboutPage'));
const TripsOverviewNew = React.lazy(() => import('../components/TripsOverviewNew'));
const EcoDriveSubpage = React.lazy(() => import('../components/Graphs/EcoDrive/EcoDriveSubpage'));
// const Page = React.lazy(() => import('./components/Maintenance/Page'));
const OnlineMapNew = React.lazy(() => import('../components/OnlineMapNew'));
const OnlinePanelAssign = React.lazy(() => import('../components/OnlinePanel/AssignTable'));
const AutoReportLogs = React.lazy(() => import('../components/AutoReports/AutoReportLogs'));
const AlarmLogs = React.lazy(() => import('../components/Alarms/AlarmLogs'));
const ImportRecord = React.lazy(() => import('../components/ImportRefuelings/ImportRecord'));
const ImportTemplates = React.lazy(() => import('../components/ImportRefuelings/ImportTemplates'));
const ParkingCodebooks = React.lazy(() => import('../components/Codebooks/ParkingCodebooks'));
const PeriodLockSetting = React.lazy(() => import('../components/PeriodLock/PeriodLockSetting'));
const GdprUserHistory = React.lazy(() => import('../components/GdprUsersList/GdprUserHistory'));
const GdprProfileHistory = React.lazy(() => import('../components/GdprProfile/GdprProfileHistory'));
const NavigatorData = React.lazy(() => import('../components/NavigatorData'));
const Customers = React.lazy(() => import('../components/Customers'));
const EvBatteryKwOnline = React.lazy(() => import('../components/Graphs/EvBatteryKw'));
const EvBatteryPercOnline = React.lazy(() => import('../components/Graphs/EvBatteryPerc'));
const AdBluePercOnline = React.lazy(() => import('../components/Graphs/AdBluePerc'));
const Logout = React.lazy(() => import('../components/Logout'));

const path = window.location.pathname.replace(/\/$/, '');
if (path === URL_LOGIN && UserService.isLogged()) {
  const language = localStorage.getItem('language');
  localStorage.clear();
  if (language) {
    localStorage.setItem('language', language);
  }
}

export default function AllRoutes(): JSX.Element {
  const setIsRidePlayerOpen = useSetRecoilState<number | boolean>(OnlineMapState.isRidePlayerOpen);
  const setIsOnlinePanelOpen = useSetRecoilState<number | false>(
    OnlinePanelState.isOnlinePanelOpen
  );
  const setAutofocus = useSetRecoilState<boolean>(OnlineMapState.autofocus);
  const prevLocation = usePrevLocation();
  const selectedCustomers = useRecoilValue<number[]>(CustomersState.selectedCustomers);
  const { isMaintenancePageActive } = useMaintenance();

  React.useEffect(() => {
    if (prevLocation === URL_MAPS) {
      // console.log('prevMap: ', prevLocation);
      setIsRidePlayerOpen(false);
      setIsOnlinePanelOpen(false);
      setAutofocus(true);
    }
  }, [prevLocation, setAutofocus, setIsOnlinePanelOpen, setIsRidePlayerOpen]);

  if (isMaintenancePageActive) {
    return <Page />;
  }

  return (
    <div style={{ height: '100%' }}>
      <ErrorBoundary>
        <React.Suspense fallback={<CmdLoader />}>
          {UserService.isLogged() && !window.location.href.includes('trip-map') && (
            <>
              <SocketConnection />
              <NavigatorData />
              <Customers />
              {/* <EmitterEffect /> */}
            </>
          )}
          <Switch>
            <Route path="/">
              <Layout component={CmdLoader} />
            </Route>
            <Route path={URL_LOGIN}>
              <Layout component={Login} />
            </Route>
            <Route path={URL_TRIPS_OVERVIEW}>
              <Layout
                component={TripsOverviewNew}
                isNavigatorDisabled={false}
                access={PermissionsService.hasAccess(PERMISSION_RIDES, ['read'], selectedCustomers)}
                isRideBook
              />
            </Route>
            <Route path={`${URL_TRIPS_OVERVIEW}/add`}>
              <Layout
                component={TripsOverviewNew}
                isNavigatorDisabled={false}
                access={PermissionsService.hasAccess(
                  PERMISSION_NEW_RIDE,
                  ['create'],
                  selectedCustomers
                )}
                isRideBook
              />
            </Route>
            <Route path={`${URL_TRIPS_OVERVIEW}/edit/:vehicleId/:rideId`}>
              <Layout
                component={TripsOverviewNew}
                isNavigatorDisabled={false}
                access={PermissionsService.hasAccess(
                  PERMISSION_NEW_RIDE,
                  ['update'],
                  selectedCustomers
                )}
                isRideBook
              />
            </Route>
            <Route path="/overview-tours/:vehicleId">
              <RideMapLayout
                component={TripsOverviewNew}
                access={PermissionsService.hasAccess(PERMISSION_RIDES, ['read'], selectedCustomers)}
              />
            </Route>
            <Route path={`${URL_TRIPS_OVERVIEW_WITH_ECO_DRIVE}/:vehicleId/:rideId`}>
              <Layout
                component={EcoDriveSubpage}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(PERMISSION_RIDES, ['read'], selectedCustomers)}
              />
            </Route>
            <Route path={URL_VEHICLES_SETTINGS}>
              <Layout
                component={VehiclesSettings}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_VEHICLE,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={URL_ABOUT}>
              <Layout component={AboutPage} isNavigatorDisabled={true} access={true} />
            </Route>
            <Route path={`${URL_VEHICLES_SETTINGS}/edit/:id`}>
              <Layout
                component={VehiclesSettings}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_VEHICLE,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={URL_MAPS}>
              <Layout
                component={OnlineMapNew}
                access={PermissionsService.hasAccess(
                  PERMISSION_MAP_SHOW_VEHICLE,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>

            {/* <Route path="/ride-map/:id">
              <RideMapLayout
                component={RideMap}
                access={PermissionsService.hasAccess(
                  PERMISSION_MAP_SHOW_VEHICLE,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route> */}
            <Route path="/trip-map/:id">
              <RideMapLayout
                component={TripMap}
                access={PermissionsService.hasAccess(
                  PERMISSION_MAP_SHOW_VEHICLE,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path="/trip-map">
              <RideMapLayout
                component={TripMap}
                access={PermissionsService.hasAccess(
                  PERMISSION_MAP_SHOW_VEHICLE,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>

            <Route path={`${URL_GRAPHS}${URL_ECO_DRIVE}/:vehicleId`}>
              <RideMapLayout component={EcoDriveOnline} />
            </Route>

            <Route path={`${URL_GRAPHS}${URL_VELOCITY}/:vehicleId`}>
              <RideMapLayout component={VelocityOnline} />
            </Route>

            <Route path={`${URL_GRAPHS}${URL_FUEL_LEVEL_PERCENTAGE}/:vehicleId`}>
              <RideMapLayout component={FuelLevelPercOnline} />
            </Route>

            <Route path={`${URL_GRAPHS}${URL_FUEL_LEVEL_LITERS}/:vehicleId`}>
              <RideMapLayout component={FuelLevelLitersOnline} />
            </Route>

            <Route path={`${URL_GRAPHS}${URL_EV_BATTERY_KW}/:vehicleId`}>
              <RideMapLayout component={EvBatteryKwOnline} />
            </Route>

            <Route path={`${URL_GRAPHS}${URL_EV_BATTERY_PERCENT}/:vehicleId`}>
              <RideMapLayout component={EvBatteryPercOnline} />
            </Route>

            <Route path={`${URL_GRAPHS}${URL_AD_BLUE_PERCENT}/:vehicleId`}>
              <RideMapLayout component={AdBluePercOnline} />
            </Route>

            <Route path={`${URL_GRAPHS}${URL_THROTTLE}/:vehicleId`}>
              <RideMapLayout component={ThrottleOnline} />
            </Route>

            <Route path={`${URL_GRAPHS}/:name/:vehicleId`}>
              <RideMapLayout component={OrdinaryGraphOnline} />
            </Route>

            <Route path={URL_ALARM}>
              <Layout
                component={Alarms}
                access={PermissionsService.hasAccess(PERMISSION_ALARM, ['read'], selectedCustomers)}
                isNavigatorDisabled={true}
              />
            </Route>

            <Route path={`${URL_ALARM}/add`}>
              <Layout
                component={Alarms}
                access={PermissionsService.hasAccess(PERMISSION_ALARM, ['read'], selectedCustomers)}
                isNavigatorDisabled={true}
              />
            </Route>

            <Route path={`${URL_ALARM}/log`}>
              <Layout
                component={AlarmLogs}
                access={PermissionsService.hasAccess(PERMISSION_ALARM, ['read'], selectedCustomers)}
                isNavigatorDisabled={true}
              />
            </Route>

            <Route path={`${URL_ALARM}/edit/:id`}>
              <Layout
                component={Alarms}
                access={PermissionsService.hasAccess(PERMISSION_ALARM, ['read'], selectedCustomers)}
                isNavigatorDisabled={true}
              />
            </Route>

            <Route path={`${URL_EXPENSE_IMPORT}/wizard`}>
              <Layout
                component={ImportRefuelingsWizard}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_IMPORTED_RECORD,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>

            <Route path={URL_EXPENSE_IMPORT}>
              <Layout
                component={ImportRefuelings}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_IMPORTED_RECORD,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>

            <Route path={`${URL_EXPENSE_IMPORT}/expense/import/record`}>
              <Layout
                component={ImportRefuelings}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_IMPORTED_RECORD,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>

            <Route path={`${URL_EXPENSE_IMPORT}/expense/import/record/edit/:id`}>
              <Layout
                component={ImportRefuelings}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_IMPORTED_RECORD,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>

            <Route path={`${URL_EXPENSE_IMPORT}/expense/import`}>
              <Layout
                component={ImportRecord}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_IMPORTED_RECORD,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>

            <Route path={`${URL_EXPENSE_IMPORT}/edit/:id`}>
              <Layout
                component={ImportRecord}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_IMPORTED_RECORD,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>

            <Route path={`${URL_EXPENSE_IMPORT}/expense/import-template`}>
              <Layout
                component={ImportTemplates}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_IMPORTED_RECORD,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>

            <Route path={URL_SUPPLIERS}>
              <Layout
                component={Suppliers}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_SUPPLIER,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_SUPPLIERS}/add`}>
              <Layout
                component={Suppliers}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_SUPPLIER,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_SUPPLIERS}/edit/:id`}>
              <Layout
                component={Suppliers}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_SUPPLIER,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path="/costs">
              <Layout
                component={OperatingCostsOverView}
                isNavigatorDisabled={false}
                access={PermissionsService.hasAccess(PERMISSION_COST, ['read'], selectedCustomers)}
              />
            </Route>
            <Route path="/costs/add">
              <Layout
                component={OperatingCostsOverView}
                isNavigatorDisabled={false}
                access={PermissionsService.hasAccess(PERMISSION_COST, ['read'], selectedCustomers)}
              />
            </Route>
            <Route path="/costs/edit/:id">
              <Layout
                component={OperatingCostsOverView}
                isNavigatorDisabled={false}
                access={PermissionsService.hasAccess(PERMISSION_COST, ['read'], selectedCustomers)}
              />
            </Route>
            <Route path={URL_EXPENSE_TYPES}>
              <Layout
                component={OperatingCostsTypes}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_EXPENSE_TYPE,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_EXPENSE_TYPES}/add`}>
              <Layout
                component={OperatingCostsTypes}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_EXPENSE_TYPE,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_EXPENSE_TYPES}/edit/:id`}>
              <Layout
                component={OperatingCostsTypes}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_EXPENSE_TYPE,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={URL_REFUELING}>
              <Layout
                component={Refuelings}
                access={PermissionsService.hasAccess(
                  PERMISSION_REFUELING,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_REFUELING}/add`}>
              <Layout
                component={Refuelings}
                access={PermissionsService.hasAccess(
                  PERMISSION_REFUELING,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_REFUELING}/edit/:id`}>
              <Layout
                component={Refuelings}
                access={PermissionsService.hasAccess(
                  PERMISSION_REFUELING,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_REFUELING}/:vehicleId`}>
              <RideMapLayout
                component={Refuelings}
                access={PermissionsService.hasAccess(
                  PERMISSION_REFUELING,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>

            <Route path={URL_CORRECTIONS}>
              <Layout
                component={Corrections}
                access={PermissionsService.hasAccess(
                  PERMISSION_TACHO_CORRECTION,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_CORRECTIONS}/add`}>
              <Layout
                component={Corrections}
                access={PermissionsService.hasAccess(
                  PERMISSION_TACHO_CORRECTION,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_CORRECTIONS}/edit/:id`}>
              <Layout
                component={Corrections}
                access={PermissionsService.hasAccess(
                  PERMISSION_TACHO_CORRECTION,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_CORRECTIONS}/:vehicleId`}>
              <RideMapLayout
                component={Corrections}
                access={PermissionsService.hasAccess(
                  PERMISSION_TACHO_CORRECTION,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path="/period-lock">
              <Layout
                component={PeriodLock}
                access={PermissionsService.hasAccess(
                  PERMISSION_PERIOD_LOCK,
                  ['read'],
                  selectedCustomers
                )}
                isNavigatorDisabled={true}
              />
            </Route>
            <Route path="/period-lock/definition">
              <Layout
                component={PeriodLockSetting}
                access={PermissionsService.hasAccess(
                  PERMISSION_PERIOD_LOCK,
                  ['read'],
                  selectedCustomers
                )}
                isNavigatorDisabled={true}
              />
            </Route>
            <Route path="/period-lock/definition/add">
              <Layout
                component={PeriodLockSetting}
                access={PermissionsService.hasAccess(
                  PERMISSION_PERIOD_LOCK,
                  ['read'],
                  selectedCustomers
                )}
                isNavigatorDisabled={true}
              />
            </Route>
            <Route path="/period-lock/definition/edit/:id">
              <Layout
                component={PeriodLockSetting}
                access={PermissionsService.hasAccess(
                  PERMISSION_PERIOD_LOCK,
                  ['read'],
                  selectedCustomers
                )}
                isNavigatorDisabled={true}
              />
            </Route>
            <Route path={URL_GDPR_PROFILE}>
              <Layout
                component={GdprProfile}
                access={PermissionsService.hasAccess(
                  PERMISSION_GDPR_PROFILE_LIST,
                  ['read'],
                  selectedCustomers
                )}
                isNavigatorDisabled={true}
              />
            </Route>
            <Route path={`${URL_GDPR_PROFILE}/add`}>
              <Layout
                component={GdprProfile}
                access={PermissionsService.hasAccess(
                  PERMISSION_GDPR_PROFILE_LIST,
                  ['read'],
                  selectedCustomers
                )}
                isNavigatorDisabled={true}
              />
            </Route>
            <Route path={`${URL_GDPR_PROFILE}/edit/:cid/:id/:count`}>
              <Layout
                component={GdprProfile}
                access={PermissionsService.hasAccess(
                  PERMISSION_GDPR_PROFILE_LIST,
                  ['read'],
                  selectedCustomers
                )}
                isNavigatorDisabled={true}
              />
            </Route>
            <Route path={`${URL_GDPR_PROFILE}/edit/:customerId/:id`}>
              <Layout
                component={GdprProfile}
                access={PermissionsService.hasAccess(
                  PERMISSION_GDPR_PROFILE_LIST,
                  ['read'],
                  selectedCustomers
                )}
                isNavigatorDisabled={true}
              />
            </Route>
            <Route path={`${URL_GDPR_PROFILE}/detail/:id`}>
              <Layout
                component={GdprProfile}
                access={PermissionsService.hasAccess(
                  PERMISSION_GDPR_PROFILE_LIST,
                  ['read'],
                  selectedCustomers
                )}
                isNavigatorDisabled={true}
              />
            </Route>
            <Route path={`${URL_GDPR_PROFILE}/history`}>
              <Layout
                component={GdprProfileHistory}
                access={PermissionsService.hasAccess(
                  PERMISSION_GDPR_PROFILE_LIST,
                  ['read'],
                  selectedCustomers
                )}
                isNavigatorDisabled={true}
              />
            </Route>
            <Route path={`${URL_GDPR_PROFILE}/history/edit/:id`}>
              <Layout
                component={GdprProfileHistory}
                access={PermissionsService.hasAccess(
                  PERMISSION_GDPR_PROFILE_LIST,
                  ['read'],
                  selectedCustomers
                )}
                isNavigatorDisabled={true}
              />
            </Route>
            <Route path={`${URL_GDPR_USER}`}>
              <Layout
                component={GdprUsersList}
                access={PermissionsService.hasAccess(
                  PERMISSION_GDPR_USER_LIST,
                  ['read'],
                  selectedCustomers
                )}
                isNavigatorDisabled={true}
              />
            </Route>
            <Route path={`${URL_GDPR_USER}/history`}>
              <Layout
                component={GdprUserHistory}
                access={PermissionsService.hasAccess(
                  PERMISSION_GDPR_USER_LIST,
                  ['read'],
                  selectedCustomers
                )}
                isNavigatorDisabled={true}
              />
            </Route>
            <Route path={URL_FUEL_CARDS}>
              <Layout
                component={FuelCards}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_REFUELING_CARDS,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_FUEL_CARDS}/add`}>
              <Layout
                component={FuelCards}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_REFUELING_CARDS,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_FUEL_CARDS}/edit/:id`}>
              <Layout
                component={FuelCards}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_REFUELING_CARDS,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={URL_CODEBOOKS}>
              <Layout
                component={Codebooks}
                isNavigatorDisabled={true}
                access={
                  PermissionsService.hasAccess(PERMISSION_RIDE_TAG, ['read'], selectedCustomers) ||
                  PermissionsService.hasAccess(PERMISSION_PARKING_TAG, ['read'], selectedCustomers)
                }
              />
            </Route>

            <Route path={`${URL_CODEBOOKS}/ride-tag`}>
              <Layout
                component={Codebooks}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_RIDE_TAG,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_CODEBOOKS}/ride-tag/add`}>
              <Layout
                component={Codebooks}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_RIDE_TAG,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_CODEBOOKS}/ride-tag/edit/:id`}>
              <Layout
                component={Codebooks}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_RIDE_TAG,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>

            <Route path={`${URL_CODEBOOKS}/parking-tag`}>
              <Layout
                component={ParkingCodebooks}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_PARKING_TAG,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_CODEBOOKS}/parking-tag/add`}>
              <Layout
                component={ParkingCodebooks}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_PARKING_TAG,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_CODEBOOKS}/parking-tag/edit/:id`}>
              <Layout
                component={ParkingCodebooks}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_PARKING_TAG,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={URL_BLUECOINS}>
              <Layout
                component={Bluecoins}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_BLUECOINS,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_BLUECOINS}/detail/:id`}>
              <Layout
                component={Bluecoins}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_BLUECOINS,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_BLUECOINS}/use-creation-settings/:id`}>
              <Layout
                component={Bluecoins}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_BLUECOINS_USE_CREATION_SETTINGS,
                  ['update'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={URL_BLUECOINS_USAGE}>
              <Layout
                component={BluecoinsUsage}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_BLUECOINS,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={URL_EMPLOYEE}>
              <Layout
                component={Employee}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_PERSON,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_EMPLOYEE}/add`}>
              <Layout
                component={Employee}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_PERSON,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_EMPLOYEE}/edit/:id`}>
              <Layout
                component={Employee}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_PERSON,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={URL_ONLINE_PANEL}>
              <Layout
                component={OnlinePanel}
                isNavigatorDisabled={true}
                access={
                  PermissionsService.hasAccess(
                    PERMISSION_ONLINE_MAP_TEMPLATES_CUSTOMER,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    PERMISSION_ONLINE_MAP_TEMPLATES_PRIVATE,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    PERMISSION_ONLINE_MAP_TEMPLATES_SYSTEM,
                    ['read'],
                    selectedCustomers
                  )
                }
              />
            </Route>
            <Route path={`${URL_ONLINE_PANEL}/templates`}>
              <Layout
                component={OnlinePanel}
                isNavigatorDisabled={true}
                access={
                  PermissionsService.hasAccess(
                    PERMISSION_ONLINE_MAP_TEMPLATES_CUSTOMER,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    PERMISSION_ONLINE_MAP_TEMPLATES_PRIVATE,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    PERMISSION_ONLINE_MAP_TEMPLATES_SYSTEM,
                    ['read'],
                    selectedCustomers
                  )
                }
              />
            </Route>
            <Route path={`${URL_ONLINE_PANEL}/templates/add`}>
              <Layout
                component={OnlinePanel}
                isNavigatorDisabled={true}
                access={
                  PermissionsService.hasAccess(
                    PERMISSION_ONLINE_MAP_TEMPLATES_CUSTOMER,
                    ['read', 'create'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    PERMISSION_ONLINE_MAP_TEMPLATES_PRIVATE,
                    ['read', 'create'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    PERMISSION_ONLINE_MAP_TEMPLATES_SYSTEM,
                    ['read', 'create'],
                    selectedCustomers
                  )
                }
              />
            </Route>
            <Route path={`${URL_ONLINE_PANEL}/assign`}>
              <Layout
                component={OnlinePanelAssign}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGN,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_ONLINE_PANEL}/templates/edit/:id`}>
              <Layout
                component={OnlinePanel}
                isNavigatorDisabled={true}
                access={
                  PermissionsService.hasAccess(
                    PERMISSION_ONLINE_MAP_TEMPLATES_CUSTOMER,
                    ['read', 'update'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    PERMISSION_ONLINE_MAP_TEMPLATES_PRIVATE,
                    ['read', 'update'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    PERMISSION_ONLINE_MAP_TEMPLATES_SYSTEM,
                    ['read', 'update'],
                    selectedCustomers
                  )
                }
              />
            </Route>
            <Route path={URL_VEHICLES_GROUPS}>
              <Layout
                component={CarsGroups}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_VEHICLE_GROUP_CUSTOMER,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_VEHICLES_GROUPS}/add`}>
              <Layout
                component={CarsGroups}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_VEHICLE_GROUP_CUSTOMER,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={`${URL_VEHICLES_GROUPS}/edit/:id`}>
              <Layout
                component={CarsGroups}
                isNavigatorDisabled={true}
                access={PermissionsService.hasAccess(
                  PERMISSION_VEHICLE_GROUP_CUSTOMER,
                  ['read'],
                  selectedCustomers
                )}
              />
            </Route>
            <Route path={URL_REPORTS}>
              <Layout
                component={Reports}
                isNavigatorDisabled={true}
                access={
                  PermissionsService.hasAccess(
                    `${PERMISSION_REPORT}01`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_REPORT}02`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_REPORT}03`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_REPORT}04`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_REPORT}05`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_REPORT}06`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_REPORT}07`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_REPORT}08`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_REPORT}11`,
                    ['read'],
                    selectedCustomers
                  )
                }
              />
            </Route>
            <Route path={URL_AUTO_REPORTS}>
              <Layout
                component={AutoReports}
                isNavigatorDisabled={true}
                access={
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}01`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}02`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}03`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}04`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}05`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}06`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}07`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}08`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}11`,
                    ['read'],
                    selectedCustomers
                  )
                }
              />
            </Route>
            <Route path={`${URL_AUTO_REPORTS}/add`}>
              <Layout
                component={AutoReports}
                isNavigatorDisabled={true}
                access={
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}01`,
                    ['create'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}02`,
                    ['create'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}03`,
                    ['create'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}04`,
                    ['create'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}05`,
                    ['create'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}06`,
                    ['create'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}07`,
                    ['create'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}08`,
                    ['create'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}11`,
                    ['create'],
                    selectedCustomers
                  )
                }
              />
            </Route>
            <Route path={`${URL_AUTO_REPORTS}/log`}>
              <Layout
                component={AutoReportLogs}
                isNavigatorDisabled={true}
                access={
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}01`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}02`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}03`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}04`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}05`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}06`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}07`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}08`,
                    ['read'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}11`,
                    ['read'],
                    selectedCustomers
                  )
                }
              />
            </Route>
            <Route path={`${URL_AUTO_REPORTS}/edit/:id`}>
              <Layout
                component={AutoReports}
                isNavigatorDisabled={true}
                access={
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}01`,
                    ['read', 'update'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}02`,
                    ['read', 'update'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}03`,
                    ['read', 'update'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}04`,
                    ['read', 'update'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}05`,
                    ['read', 'update'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}06`,
                    ['read', 'update'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}07`,
                    ['read', 'update'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}08`,
                    ['read', 'update'],
                    selectedCustomers
                  ) ||
                  PermissionsService.hasAccess(
                    `${PERMISSION_AUTO_REPORT}11`,
                    ['read', 'update'],
                    selectedCustomers
                  )
                }
              />
            </Route>

            <Route path={URL_LOGOUT}>
              <Layout component={Logout} access={true} />
            </Route>

            <Route path={URL_FORGOT_PASSWORD}>
              <ForgotPassword />
            </Route>

            <Route path={`${URL_REPORT_DOWNLOAD}/:id`}>
              <EmailDownload />
            </Route>

            <Route path={`${URL_RESET_PASSWORD}/:token`}>
              <LanguagesContext.Consumer>
                {(context: LanguageContextProps) => <ResetPassword context={context} />}
              </LanguagesContext.Consumer>
            </Route>

            <Route path={`${URL_ACTIVATE_ACCOUNT}/:token`}>
              <LanguagesContext.Consumer>
                {(context: LanguageContextProps) => <ActivateAccount context={context} />}
              </LanguagesContext.Consumer>
            </Route>

            <Route path={`${URL_EXPIRED_PASSWORD}/:token`}>
              <LanguagesContext.Consumer>
                {(context: LanguageContextProps) => <ExpiredPassword context={context} />}
              </LanguagesContext.Consumer>
            </Route>
            {UserService.isLogged() ? (
              <Route>
                <Error404 />
              </Route>
            ) : (
              <Route>
                <Layout component={Login} />
              </Route>
            )}
          </Switch>
        </React.Suspense>
      </ErrorBoundary>
    </div>
  );
}
